/* .drawerbg{
    background-color: #3f51b5;
}
.logo{
    width: 100%;
    height: 100%;
}
.logodiv{
    background-color: #3f51b5;
} */
.liststyling{
    color: black;
    background-color: blueviolet;
    text-decoration: none;
}
.textcolor{
    color: black;
}
.logoutbtn{
    color: black;
}
.tablehead{
    background-color: #3F51B5;
}
.tabletextcolor{
    color: #fff;
}
.MuiTableCell-head{
    color: #fff!important;
}
