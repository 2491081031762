.cardAllign{
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

thead button {
  background: none;
  border: none;
  padding: 10px;
  margin-left: 10px;
  color: white;
}
.leftsidegrid{
  float: right;
}
.ulliadmin{
  line-height: 7px;
  margin-right: 150px;
}
.h6adminaoe{
  margin-bottom: 30px;
  margin-left: 20px;
}

thead button.ascending::after {
  content: ' \02C5';
  display: inline-block;
  margin-left: 1em;

}

thead button.descending::after {
  content: ' \02C4';
  display: inline-block;
  margin-left: 1em;
}


  
  .card{
      display: block;
  margin-left: auto;
  margin-right: auto;
      width: 450px;
  }
  
  .card h3{
      text-align: center;
  }
  
  label{
      text-align: center;
  }
  .img{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .btn-primary{
      width: 200px;
     display: block;
     margin-left: auto;
     margin-right: auto;
  
  }
  
  a{
      color: white !important;
      display: flex;
      text-align: center;
  }


  .user-profile {

    padding: 50px
  }



  .user-profile span {
    font-weight: bold;
  }


  .profile-heading {
      
    text-align: center;
    font-family: 'Roboto';
    letter-spacing: unset;

  }